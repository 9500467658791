.titlepage {
  display: flex;
  align-items: center;
gap:1rem;
height: 48px;
}

.titlepage-title {
  margin-right: 16px;
  width: 40%;
  font-weight: 400;
}

.titlepage-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
}


