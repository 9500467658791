.customSelect {
  font-size: 24px; /* Tamaño del texto */
  border: none; /* Eliminar borde */
  appearance: none; /* Ocultar botón de despliegue */
  -webkit-appearance: none; /* Compatibilidad con WebKit (Chrome, Safari) */
  -moz-appearance: none; /* Compatibilidad con Mozilla (Firefox) */
  padding: 8px; /* Espacio alrededor del texto */
  background-color: #f0f0f0; /* Color de fondo */
  width: 100%; /* Ancho completo del contenedor */
  cursor: pointer; /* Cambiar el cursor para dar una mejor experiencia de usuario */
}

/* Si quieres ocultar el icono de despliegue también en IE y Edge */
select::-ms-expand {
  display: none;
}
