.player-card{
  padding: 20px;
  border-radius: 8px;
  border:solid 1px #e5e5e5;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;


  
}
.player-card-shirt{
  min-width: 150px;
}
.player-card-field{
  transform: rotate(-90deg);
  height: 100px;
}
.player-card-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.player-card-stats{
  margin-left: 100px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}


.player-card-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.player-card-img {
  position: relative;
  display: inline-block;
  width: 50%;
  margin-bottom: 10px;
}

.player-card-img img {
  display: block;
  width: 100%;
}

.player-card-stat-title {
  font-size: 14px;
  font-weight: 500;
  color: #888;
}

.player-card-stat-value {
  font-size: 27px;
  font-weight: 700;
}

@media screen and (max-height: 720px) {
  .jugador-card {
    max-height: 60vh;
    overflow-x: auto;
  }
}

.hover-delete-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.delete-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0; 
  width: 50px;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 10;
  border: none; 
  padding: 0; 
  margin: 0; 
}

.hover-delete-container:hover .delete-overlay {
  opacity: 1;
  transform: translateX(0); 
}

.delete-overlay-hidden {
  transform: translateX(100%);
}

