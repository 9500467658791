.login-popup {
    display: flex;
    width: 100%;
  }
  
  .login-popup-left {
    flex: 0 0 380px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-popup-image {
    border-radius: 8px;
    width: 100%;
  }
  
  .login-popup-right {
    flex: 2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .login-popup-input {
    margin-bottom: 10px;
  }
  
  .login-popup-button {
    width: 100%;
   background-color: var(--primary) ;
   color: white;
  }
  .login-popup-button:hover{
    background-color: var(--primaryLigth) !important;
  }

  
  .login-text {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  
  .login-label {
    float: right;
  }
  
  .login-input-text{
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    color: #979EA8;
  
  }