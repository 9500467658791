:where(.css-dev-only-do-not-override-3rel02).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
    border: none !important;
    border-radius: 4px !important;

}


:where(.css-dev-only-do-not-override-3rel02).ant-select-single .ant-select-selector .ant-select-selection-item{
    display: flex!important;
    justify-content: center!important;
   
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    display: flex!important;
    justify-content: center!important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
    background: none !important;
    color: black!important;  
}
.select-ghost {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
  }

  .select-full {
    border: none !important;
  }