.CustomCard{
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 20px 20px 20px;
    margin: 0;
    position: relative;
    height: 100%;
   max-height: 82vh;

   overflow-y: none;
}