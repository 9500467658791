.fixture-list {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .fecha-info {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .fecha-grande {
    font-size: 24px;
    font-weight: bold;
  }
  
  .info-chica {
    font-size: 14px;
    color: #666;
  }
  
  .partidos {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Espacio entre los FixturePartido */
  }
  
 