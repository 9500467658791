.team-card {
  border-radius: 2px;
  width: 100%;
}

.teams-card {
  padding: 20px;
  border-radius: 8px;
  border: solid 1px #e5e5e5;
  margin-bottom: 20px;
 

}
.teams-card-header{
 display: flex;
 align-items: center;
 gap: 20px;
}

.team-color {
  display: flex;
  justify-content: end;
}
.team-card-img {
  position: relative;
  display: inline-block;
  width: 50%;
  margin-bottom: 10px;
}

.team-card-img img {
  display: block;
  width: 100%;
}

.team-card-text {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

.team-asociar {
  color: gray;
  display: flex;
  align-items: center;
  gap: 15px;
}
.asociar-text {
  color: #979ea8;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.team-federation-card{
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  border-left: solid 8px var(--primary);
 padding: 10px;
 margin-bottom: 10px;
 transition: border-left-width 0.05s ease-in-out;
 cursor: pointer;
}
.team-federation-card:hover {
  border-left-width: 10px;
}

.team-federation-sport{
  font-weight: 600;
  font-size: 12px;
  color: #8888;
}
.team-federation-logo{
  height: 40px;
  width: 40px;
}
.team-federation-body{
  display: flex;
  align-items: center;
  gap: 20px;

}
.team-federation-details{
  display: flex;
  flex-direction: column;
}


.team-federation-inputs {
  display: flex;
  gap: 5px;
  height: 35px;
  

}

.team-federation-input {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
}

.team-federation-input.largo {
  flex: 1;
}

.team-federation-input.corto {
  flex: 0.5;
}
.team-federation-status-icon{
  height: 40px;
  width: 40px;
}
.team-federation-input-placeholder {
  width: 30px;  
  display: inline-block;
}