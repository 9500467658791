.shirtContainer {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.shirtImageContainer {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  align-items: center;
}

.shirtImage {
  position: absolute;
  width: auto;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.shirtNumber {
  position: absolute;
  top: 23%;
  left: 35%;
  transform: translateX(-50%);
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  font-size: 1.5vw;
  color: white;
}

.shirtName {
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: 1vw;
  color: black;
}
