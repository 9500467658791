.check-match-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 20px;
  }
  

  
  .menu-grid {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 30px;
    
  }
  .icon-container {
    background-color: #87FB31; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 10px;
    margin-bottom: 16px;
  }
  
  .menu-item-large {
    grid-row: span 2;
    background-color: #fff;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    padding: 16px;
    height: 300px;
    
    font-weight: 600;
    font-size: 18px;
    text-align: center;
 

    transition: transform 0.3s ease;
  }
  .icon-play{
    margin-left: 10px;
  }
  .menu-item-small {
    background-color: #fff;
    color: #000;
    display: flex;
    width: 300px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    padding: 8px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    gap: 10px;
   
  }
  
  .menu-item-large:hover, .menu-item-small:hover {
    background-color: #40a9ff;
    color: white;
    transition: background-color 0.3s ease;
  }
  