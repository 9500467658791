.shirtpopup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .shirtpopup-container {
    display: flex;
    width: 900px; 
    background: white;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .shirtpopup-content {
    flex: 0.70;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
  }
  
  .shirtpopup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  
  }
  

  .shirtpopup-title {
    font-weight: 500;
    font-size: 20px;
  }
  
  .shirtpopup-side {
    flex: 0.30;
    background-color: #f0f0f0; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  .shirtpopup-circle {
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: white;
    border-radius: 50%;
    z-index: 0; 
  }
  
  .shirtpopup-side > .shirt {
    position: relative;
    z-index: 1; 

   
  }