.button {
    padding: 10px 50px;
    background-color: var(--primary);
    color: white;
    border: none;
    border-radius: 26px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  .text {
    margin-top: 10px;
    font-size: 18px; 
    color: black;
    font-weight: 500;
    width: 100%; 
  }
  .subText {

    font-size: 16px; 
    color: black;
    font-weight: 500;
  
  }
  .header {
    display: flex;
    justify-content: space-between; 
    align-items: flex-start; 
    padding: 20px 0px;
    gap: 20px;
  }