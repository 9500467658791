.buttonSave {
  cursor: pointer;
  background-color: #0054E9;
  border-radius: 5px;
margin-top: 10px;
  min-width: 70px;
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 40px;
  color: white;
  font-size: 20px;
  font-weight: bolder;
}
