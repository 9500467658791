.crono-tarjetas {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
  
    border-radius: 10px;
  }
  
  .tarjetas-columna {
    display: flex;
    flex-direction: column;
    width: 45%;
  }
  
  .jugador-tarjeta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    font-size:13px ;
    font-weight: 500;
   background-color: #fff;
   border-radius: 3px;
  }
  
  .jugador-tarjeta span {
    margin: 5px;
  }
  
  .crono-tarjetas-text{
    font-weight: 500;
    font-size: 16px;
  }