.activity-shirt {
  position: relative;
  height: 100px;
}

.activity-shirt-image {
  width: auto;
  height: 100%;
 

}

.activity-shirt-number {
  position: absolute;
  top: 15%;
  left:48%;
  font-family: 'numberfont', sans-serif;
  font-weight: bold;
  font-size: 50px;
}
.reverse-number{
  left:10%;
  right:45% !important ;
}
.activity-shirt-text {
  position: absolute;
  top: 15%;
  left: 50%;
  font-family: 'numberfont', sans-serif;
  font-weight: bold;
  font-size: 1.2vw;
}
