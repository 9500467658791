/* General Styles */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

/* Small Header */
.small-header {
    background-color: black;
    color: white;
    text-align: right;
    padding: 10px 50px;
}

.small-header a {
    color: white;
    text-decoration: none;
    margin-right: 5px;
    font-weight: 300;
}

/* Large Header */
.large-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 200px;
    background-color: white;
 
}

.large-header .logo img {
    height: 50px;
}

.large-header nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.large-header nav ul li {
    margin-right: 20px;
}

.large-header nav ul li a {
    text-decoration: none;
    color: black;
    font-weight: 500;
    font-size: 17px;
}

/* Hero Section */
.carousel-container {
  position: relative;
}
.hero-section {
  position: relative;
  background-image: url("./assets/Hero2Image.png");
  background-size: cover;
  background-position: center; 
  height: 87vh; 
  display: flex;
  align-items: flex-start; 
  justify-content: center; 
 
}

.hero-text {
  position: absolute;
  top: 10%;
  text-align: center;
  color: white;
  z-index: 10;
  max-width: 700px;
}

.hero-text h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

.btn {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    font-weight: 600;
    font-size: 16px;
}

.white-btn {
    background-color: white;
    color: black;
    
}

.green-btn {
    background-color: #4ADA00;
    color: white;
}
/* Features Section */
.features-section {
    padding: 50px;
    text-align: center;
}

.features {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 50px;
}

.feature {
    flex: 1;
    margin: 20px;
    text-align: center;
}

.feature-img {
    width: 200px; 
    height: 200px; 
    object-fit: cover;
    margin-bottom: 10px;
}

.feature span {
    display: block;
    font-size: 18px;
    font-weight: 700;
}
/* About Section */
.about-section {
    padding: 50px 200px;
    display: flex;
    justify-content:center;
    align-items: center;
  }
  
  .about-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-image: url('./assets/AboutImage.png'); /* Ruta de la imagen de fondo */
    background-size: cover;
    background-position: center;
    padding: 60px 100px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    
  }
  
  .about-text {
    flex: 0.7;
    color: black; 
  }
  
  .about-logo img {
    width: 260px; /* Ajusta el tamaño del logo según sea necesario */
    height: auto;
    margin-left: 20px; /* Espacio entre el texto y el logo */
  }
  
  .about-card h3 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .about-card p {
    font-size: 18px;
    font-weight: 500;
  }

  /* Info Section */
.info-section {
    background-image: url('./assets/InfoBackground.png'); /* Ruta de la imagen de fondo */
    background-size: cover;
    background-position: center;
    padding: 200px 50px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .info-content-left{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 200px;
    border-radius: 10px;
    width: 100%;
  }
  
  .info-text-left {
    flex: 0.5;
    margin-right: 20px;
    
    text-align: left;
  }
  
  .info-text-left  h3 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 10px;
    color: black; /* Color negro para el título */
  }
  
  .info-text-left  p {
    font-size: 19px;
    color: #9b9b9b; /* Color gris para el párrafo */
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  .info-image {
    flex: 0.4;
  }
  
  .info-image img {
    width: 100%;
    border-radius: 10px; /* Bordes redondeados para la imagen */
  }
  
  .btn {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 20px; /* Bordes redondeados para el botón */
  }
  /* Create Tournament Section */
  .create-tournament-section {
    background-color: white; /* Fondo blanco */
    padding: 100px 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .create-tournament-content {
    text-align: center;
  }
  
  .create-tournament-content h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: black;
  }
  
  .create-tournament-content p {
    font-size: 19px;
    color: #9b9b9b; /* Color gris para el párrafo */
    margin-bottom: 20px;
    font-weight: 500;
    max-width: 700px;
  }
  
 
/* Footer */
.footer {
    display: flex;
    justify-content: space-around;
    padding: 50px;
    background-color: black;
    color: white;
}

.footer-column {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}

.footer-column span {
    font-weight: 500;
    font-size: 16px;
    color: #9b9b9b;
    margin-bottom: 5px;
}
.footer-logo{
    width: 200px;
}
.footer-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-column ul li {
    margin-bottom: 2px;
    color: white;
}

.footer-column p {
    margin: 0;
}
