.nextmatch-card{
    background-color: #fff;
    border-radius: 16px;
    padding: 10px 20px;
    margin: 10px 0;
}
.nextmatch-header{
    display: flex;
    justify-content: center;
 
   
}
.nextmatch-body{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
   
}

.nextmatch-team{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:15px;
}

.nextmatch-team-name{
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    margin-top: 10px;
}
.nextmatch-team-logo{
    width: 110px;
    height: 110px;

}
.nextmatch-info{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.nextmatch-info-textsmall{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #616161;
}
.nextmatch-info-textbold{
    font-weight: 600;
    font-size: 50px;
    line-height: 50px;
}
.nextmatch-info-textmedium{
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;
}
