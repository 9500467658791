:root {
  --primary: #020AD9;
  --primarylight: #4096ff;
  --danger: rgba(255, 0, 0, 0.842);
  --success: #15d4be;
  --info: #0dcaf0;
  --blue: #6076f3;
  --gray: rgba(117, 117, 117, 0.842);
  --orange: #ebd26f;
  --purple: #7d8aba;
  --dangerlight: #ff7474;
  --successlight: #68e3d5;
  --warninglight: #b4b4b4;
  --infolight: #65a2ff;
  --toastify-color-progress-light: #1abc9c;
  --app-container: #f3f4f7;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  font-variation-settings: 'slnt' 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--app-container);
  background-image: url('../public/background.png');
  text-decoration: none;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.status-pendiente {
  background-color: orange;
  border: none;
  color: white;
}

.status-en-espera {
  background-color: yellow;
  color: black;
}

button {
  all: unset;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}
@media screen and (max-height: 720px) {
  .small-container {
    max-height: 51vh;
    overflow-x: auto;
  }
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-color-picker-trigger
  .ant-color-picker-color-block {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-color-picker-trigger {
  border: none !important;
}
.info-btn {
  display: inline-block;
  color: #979ea8;
  border: 1.5px solid #979ea8;
  text-align: center;
  font-weight: bold;
  border-radius: 8px;
  transform: rotate(-45deg);
  cursor: pointer;
}
.info-btn span {
  display: block;
  transform: rotate(45deg);
}
:where(.css-dev-only-do-not-override-3rel02).ant-form-item
  .ant-form-item-label
  > label {
  font-family: 'Inter', sans-serif !important;
  font-weight: 500 !important;
  color: #979ea8 !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-input {
  font-family: 'Inter', sans-serif !important;
  border: 1px solid #e5e5e5 !important ;
  border-radius: 10px !important;
}

:where(.css-dev-only-do-not-override-3rel02).ant-input {
  padding: 4px 0 !important;
  border: none !important;
color: #000;
font-weight: 500;
  border-radius: 0 !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-input:focus {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.8) !important;
  outline: none !important;
  box-shadow: none !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-divider
  .ant-divider-inner-text {
  color: #979ea8 !important;
}
:where(
    .css-dev-only-do-not-override-3rel02
  ).ant-input-outlined.ant-input-disabled,
:where(.css-dev-only-do-not-override-3rel02).ant-input-outlined[disabled] {
  background-color: transparent !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  color: #000 !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-card {

  border-radius: 1px !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-tabs .ant-tabs-tab-btn {
  color: #696969;
}

