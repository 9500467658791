.account-card {
  position: relative;
  border-radius: 1px;
  width: 100%;
  background-color: white;
}

.card-banner {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center;
}

.edit-button {
  position: absolute;
  top: 5px;
  right: 10px;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.card-content {
  padding: 10px 30px;
  position: relative;
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  background-color: white;
  top: -50px;
  left: 16px;
}

.header-form {
  display: flex;

  margin-left: 150px;
}
