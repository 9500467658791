.sportTeamSelect {
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: white;
  width: 250px;
  padding: 0;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.sportTeamSelect:hover {

  border-color: #4096ff;
}
.sportTeamSelect:hover .searchIcon {
  color: #4096ff;
}

.sportTeamShirt {
  height: 50px;
  width: 85px;
  transform: scaleX(-1);
}

.sportTeamInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

.sportTeamDetails {
  display: flex;
  flex-direction: column;
}

.categoryType {
  font-size: 12px;
  font-weight: 500;
  color: #979ea8;
}

.playerName {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.searchIcon {
  margin-left: auto;
  font-size: 16px;
  color: #000;
  transition: color 0.3s ease;
}


.shirt-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 80%;
  background-color: #e0e0e0; 
  border-radius: 50%; 
}
