.tablecard {
  display: flex;
  align-items: left;
  
  align-items: center;
  padding: 0px 20px;
  border-color: #fff;
  border-radius: 2px;
  background-color: #f9f9f9;
  margin-bottom: 5px;
  cursor: pointer;
}

.active {
  background-color: antiquewhite;
}

.gender0 {
  border-left: 6px solid #ff00e5;
}
.gender1 {
  border-left: 6px solid #001aff;
}

.tag {
  border-radius: '3px';
  border: 1px solid #d9d9d9;
  background: white;
  color: #595959;
  padding: 4px 12px;

}
.clickable:hover {
  border-color: #4096ff;
  cursor: pointer;
}
.clickable:hover .tagtext{
  color: #4096ff;
}
.image {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.tagtext {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 500;
  font-size: 15;
  line-height: 18px;
  color: #979ea8;
}
.shortText {
  font-weight: 500;
  font-size: 15px;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text {
  font-weight: 500;
  font-size: 15px;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.grayText {
  display: flex;
  align-items: baseline;
  gap: 10px;
  font-weight: 500;
  font-size: 15px;
  color: #979ea8;
}

/* --------------------------- */

.match-team-info {
  display: flex;
  align-items: center;
  padding: 2px 10px;
  gap: 15px;
}

.vs {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.match-info {
  display: flex;
  align-items: center;
  margin-left: 20px;
  gap: 50px;
}
.match-info-text {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 15;
  line-height: 18px;
  color: #979ea8;
}
.match-info .icon {
  margin-right: 5px;
}

.match-info span {
  margin-right: 10px;
  font-size: 14px;
}

.match-status {
  background-color: #ff4d4f; /* Example color, change as needed */
  color: #fff;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  margin-left: 20px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.colsportimage {
  max-width: 80% !important;
}

.btn {
  display: inline-block;
  color: #979ea8;
  border: 1.5px solid #979ea8;
  text-align: center;
  font-weight: bold;
  border-radius: 8px;
  width: 25px !important;
  height: 25px !important;
  padding: 0 !important;
  transform: rotate(-45deg);
  cursor: pointer;
}
.btn span {
  display: block;
  transform: rotate(45deg);
}


.matchContainer{
 display: flex;
 align-items: center;
 width: 500px;
 height: 50px ;
 padding: 10px 40px;
 border: solid 2px #e5e5e5;
 border-radius: 10px;
 gap: 10px;
 overflow: hidden;
 position: relative;
 z-index: 9;
}


.isSelected{
  color: white;
  background-color:var(--primary);
  border-color: var(--primary);
}

/* --------- franjas matchcontainer-------------------*/


.matchColorLeft {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 150px; 
}

.matchColorLeft::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to top left,
    transparent 50%,
    var(--homeColor1) 50%
  );
  z-index: 1; 
  clip-path: polygon(0 0, 0% 100%, 70% 0);

}
.matchColorLeft::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to left ,
    transparent 50%,
    var(--homeColor2) 50%
  ); 
  z-index: 0; 
  clip-path: polygon(0 0, 49% 0, 14% 100%, 0% 100%);

}


.matchColorRight {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 150px; 
}
.matchColorRight::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 50%,
      var(--awayColor2) 50%
  );
  z-index: 0; 
  clip-path: polygon(100% 0, 51% 0, 86% 100%, 100% 100%);
}

.matchColorRight::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0px;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to top right ,
    transparent 50%,
    var(--awayColor1) 50%
  ); 
  z-index: 1; 
  clip-path: polygon(100% 0, 100% 100%, 30% 0);

}


