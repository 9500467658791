.event-period{

}

.event-period-header{
    display: flex;
    justify-content: center;
    background-color: #93FC42;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    
}
.event-period-text{
    font-weight: 700;
    font-size: 13px;
}
.event-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-left: 7px solid #FFF6C4;
    padding: 10px 20px;
    background-color: #fff;
    margin-bottom: 10px;
}
.reverse{
    flex-direction: row-reverse !important;
    border-left: none;
    border-right:7px solid #EAEAEA !important;
}
.event-text{
    font-weight: 500;
    font-size: 18px;
}
.event-text-sm{

    font-weight: 500;
    font-size: 10px;
    color: #979EA8;
}