.tournament-team-card {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    max-width: 250px;
    height: 30px;
    cursor: pointer;
  }
  
  .tournament-reverse {
    flex-direction: row-reverse;
  }
  
  .tournament-team-avatar .tournament-team-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .tournament-team-placeholder {
    width: 20px;
    height: 20px;
    background-color: #ccc;
    border-radius: 50%;
  }
  
  .tournament-team-name {
    font-size: 15px;
    color: black;
    font-weight: 500;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    max-width: 150px;
  }
  
  .tournament-team-icon {
    font-size: 18px;
    color: #888;
  }
  