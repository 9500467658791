.matchPlayer-container {
  display: flex;
  width: 500px;
  height: 60px;
  align-items: center;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 5px;
 width: 100%;
  overflow: hidden;
  margin: 5px 0 ;
}

.matchPlayer-container.reverse {
  flex-direction: row-reverse; 
}

.matchPlayer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
}
