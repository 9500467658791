.sider-button {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  gap: 10px;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: transparent;
  color: #000;
  text-decoration: none;
  border: 1.5px solid #e5e5e58c;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.sider-button.active {
  border-color: var(--primary);
  background-color: var(--primary);
  color: white;
  background-image: url('../../assets/navItemBack.svg'); 
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
