
  
  .crono-buttons-container {
    display: flex;
  
    align-items: center;
gap:100px;
    padding: 10px 50px;
    background-color: white;
    border-radius: 10px;
  }
  
  .play-button {
  width: 85px!important;
  height:  85px!important;
  border-radius:14px !important ;
font-size: 30px;
  }
  
  .small-buttons-container {
    display: flex;
    width:90%;
    justify-content: space-around;
  }
  
  .smallbtn{
    background-color: transparent!important;
    box-shadow: none!important;
    color: #979EA8!important;
    font-size: 20px;

  }
 
  