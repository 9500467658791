.subheader{
  margin-bottom: 10px;
}
.subheader-banner {
  background-color: var(--primary);
  color: white;
  padding: 10px 75px;
  border-radius: 0.375rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.liga-img {
  width: 45px;
  height: 45px;
}

.subheader-title {
  font-size: 25px;
  line-height: 30px;
  font-weight: 500;
  margin: 0;
}

.subheader-buttons {
  display: flex;
  gap: 1rem; /* space-x-4 */
  margin-top: 0.5rem; /* mt-2 */
}

.subheader-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem; /* h-10 */
  width: 100%;
  padding: 0.5rem 0.75rem; /* px-3 py-2 */
  font-size: 0.875rem; /* text-sm */
  border: 1px solid #d1d5db; /* border border-input */
  border-radius: 0.375rem; /* rounded-md */
  background-color: white; /* bg-white */
  color: black; /* text-black */
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s, color 0.2s;
}

.subheader-button:focus,
.subheader-button:hover {
  border-color: #2563eb; /* focus:border-blue-600 */
}

.subheader-icon {
  width: 1rem; /* h-4 w-4 */
  height: 1rem; /* h-4 w-4 */
  opacity: 0.5; /* opacity-50 */
}

.subheader-button[aria-expanded='true'] .subheader-icon {
  transform: rotate(180deg);
}

.subheader-button:disabled {
  cursor: not-allowed;
  opacity: 0.5; /* disabled:opacity-50 */
}
