.sport-icon {
  height: 35px;
  width: 35px;
  color: #888888;
  border: solid 1px #e5e5e5;
  border-radius: 50%;
  background-color: #f9f9f9;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sport-icon img {
  height: 85%;
  width: 85%;
  object-fit:contain;
  color: #e5e5e5;
}

.svg-icon {
  height: 100%;
  width: 100%;
  /* fill: currentColor; */
}
