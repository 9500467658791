.sideright-card {
  margin: 0;
  border-radius: 2px;
  width: 100%;
}

.sideright-card-form {
  margin-bottom: 15px;
}

.sideright-card-header {
  display: flex;
  justify-content: space-between;
  height: 30px;
}

.sideright-card-edit {
  cursor: pointer;
  color: #a4aab2;
}

.sideright-card-avatar {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
}

.sideright-card-img img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-bottom: 8px;
}

.sideright-card-options {
  width: 100%;
  margin-top: 10px;
  padding: 5px;
  color: gray;
  display: flex;
  justify-content: center;
  gap: 15px;
}
