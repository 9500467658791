.custom-input {
  padding: 2px 0px;
  border: none;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s, background-color 0.3s;
  font-size: 15px;
  font-weight: 500;
  color: black;
}

.custom-input:focus {
  border-color: #40a9ff;
  outline: none;
}

.custom-input.disabled {
  border: none;
  cursor: not-allowed;
  background-color: transparent;
}
