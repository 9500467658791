.shirtContainer {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.shirtImageContainer {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  align-items: center;
}

.shirtImage {
  position: absolute;
  width: auto;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.shirtNumber {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'numberfont', sans-serif;
  font-weight: bold;
  font-size: 3.5vw;
}
.shirtNumberSelect {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 30%;
  font-weight: bold;
  font-size: 3vw;
  color: black;
}
.shirtName {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: 0.6vw;
  color: black;
}
.circleBackground {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;

  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
