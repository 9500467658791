/* Cronometro.css */
.cronometro-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 10px ;
  }
  
  .team-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .team-logo {
    width: 160px;
    height: 160px;
  }
  
  .small-timer {
    margin-top: 150px;
    font-size: 19px;
    font-weight: 500;
    color: #000;
  }
  
  .match-timer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .match-stage {
    font-size: 16px;
    font-weight: 500;
    color: #979EA8;
  }
  
  .big-timer {
    font-size: 100px;
    font-weight: 700;
    color: #000;
  }
  
  .team-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 10px 50px;
    border-left: 7px solid #FFF6C4;
    border-right:7px solid #EAEAEA;
  }
  .team-name{
    font-weight: 500;
    font-size: 18px;
    width: 30%;
  }
  .team-name-left{
    display: flex;
    justify-content: end;
  }
  .team-score{
    font-weight: 700;
    font-size: 26px;
  }
  .team-score-container{
    display: flex;
    gap: 20px;
  }
  .green{
    color: #40ff00 !important;
  }
  .yellow{
    color: #FFFF26 !important;
  }
  
  .red{
    color: #E90F16 !important;
  }
  