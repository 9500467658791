.button-remove {
    display: inline-block;
    color: #e90f16;
    border: 1.5px solid #e90f16;
    text-align: center;
    font-weight: bold;
    border-radius: 4px;
    transform: rotate(-45deg); 
    height: 20px !important;
    width: 20px !important;
    cursor: pointer;
  }
  
  .button-remove span {
    display: block;
    transform: rotate(45deg); 
    font-size: 10px;
  }
  
  .button-remove:hover {
    color: #e90f16 !important;
    border-color: #e90f16 !important;
    background: #ffffff;
  }
  