
.inputContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}


.styledInput {
  width: 100%;
  font-size: 12px;
  padding:4px 15px; 
  border: 1px solid #E5E5E5;
  border-radius: 80px; 
  background-color: white;
  color: black;
  font-family: 'Arial', sans-serif; 
  box-sizing: border-box;

 
}

.searchIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #979EA8;
  cursor: pointer;
  font-size: 12px; 
}
