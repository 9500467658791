.posiciones-list {
    background-color: #f9f9f9; /* Fondo gris claro */
    
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .posiciones-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .posiciones-list th,
  .posiciones-list td {
    padding: 10px;
    text-align: center;
  }
  
  .posiciones-list .header {
    color: #495057; /* Gris oscuro para el texto del encabezado */
  }
  