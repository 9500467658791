
.shirtContainer {
    position: relative;
    width: 100%;
    max-width: 400px;
  
    margin: 0 auto;
    text-align: center;
  }
  
  .shirtImageContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .shirtImage {
    width: 100%;
    height: auto;
  }
  
  .shirtNumber {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'numberfont', sans-serif;
    font-weight: bold;
    font-size: 3.5vw;
  }
  
  .shirtName {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    font-size: 0.6vw;
    color: black;
  }
  