.members-form {
width: 100%;
  margin: auto;

}
.members-card{
  padding: 20px;
  border-radius: 8px;
  border:solid 1px #e5e5e5;
  margin-bottom: 20px;
  margin-top: 5px;
}

.members-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  height: 85px;
  
 
}
.members-card-name{
  display: flex;
  align-items: center;
  gap: 20px;
  

}
.members-card-name p{
  line-height: 5px;
}

.member-info{
  display: flex;
  flex-direction: row;

}
.members-card-header-img {
 margin-right: 20px;

}

.socio-form {
  background: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
}

.members-form-gender{
 font-size: 20px;

}