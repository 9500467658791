.container {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  padding: 20px;
}

.format {
  text-align: center; 
  margin: 0 10px; 
}

.iconWrapper {
  position: relative;
  width: 350px; 
  height: 350px; 
  margin: 0 auto;
}

.icon, .iconActive {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.iconActive {
  opacity: 0;
}

.format:hover .icon {
  opacity: 0;
}

.format:hover .iconActive {
  opacity: 1;
}

.text {
  margin-top: 10px;
  font-size: 18px; 
  color: black;
  font-weight: 500;
  width: 100%; 
}



.inputWrapper {
  display: flex;
  flex-direction: column; 
  width: 100%;
}

.label {
  margin-bottom: 5px; 
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.input {
  font-size: 12px;
  padding: 4px 15px; 
  border: 1px solid #E5E5E5;
  border-radius: 80px; 
  background-color: white;
  color: black;
  box-sizing: border-box;
}




.iconWrapper {
  position: relative;
  width: 350px; 
  height: 350px; 
  margin: 0 auto;
}

.icon, .iconActive {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
  transition: opacity 0.3s ease-in-out;
}

.iconActive {
  opacity: 0;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.format:hover .icon {
  opacity: 0;
}

.format:hover .iconActive {
  opacity: 1;
}
