.buttons-group {
  display: flex;
  gap: 10px;
  justify-content: end;
}

.button {
  cursor: pointer;
  border-radius: 15px;
  padding: 1px 12px;
  border: #979ea8 solid 1px;
  min-width: 70px;
  display: inline-block;
  text-align: center;
}

.save {
  border-color: #010ccc;
  background-color: #010ccc;
  color: #fff;
}

.button-edit .disabled {
  border: #ddd;
}
