/* Container to ensure styles apply correctly */
.selectContainer {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

/* Styled select */
.styledSelect {
  padding:0px 15px!important;
  font-size: 12px;
  border: 1px solid #E5E5E5;
  border-radius: 80px;
  background-color: white;
  color: #979EA8;
 height: 28px !important;
 width: 100% !important;

 
}
.placeholder {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.styledSelect .ant-select-selector:hover {
 border-color: var(--primary) !important;

}
.styledSelect option {
  border: 1px solid grey;
  border-radius: 5px !;
  padding: 3px;
  margin-top: 5px; /* Add margin to separate the options box from the select input */
}


.sortButton {
  height: 28px !important;
  border-radius: 80px;
  padding:0px 15px;
  border: 1px solid #E5E5E5;
  background: none;
  background-color: white;
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  color: #979ea8;
}