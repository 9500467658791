.avatarContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
}

.avatarCircle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
 
}

.avatarImage {
  width: 100%;
  height: auto;
  max-height: 100%;
  position: relative;
  z-index: 2; 
  object-fit: contain; 
  border-radius: 0; 
  border-radius: 50%;
}

.perfilImageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); 
  color: white;
  cursor: pointer;
  opacity: 0; 
  transition: opacity 0.3s ease; 
  border-radius: 50%;
}

.avatarContainer:hover .perfilImageOverlay {
  opacity: 1; 
}
