/* Small Header */
.federation-small-header {
    background-color: black;
    color: white;
    text-align: right;
    padding: 10px 20px;
}

.federation-small-header a {
    color: white;
    text-decoration: none;
    margin-right: 5px;
}

/* Large Header */
.federation-large-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 200px;
    background-color: #0000A7;
 
}

.federation-large-header .logo img {
    height: 50px;
}

.federation-large-header nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.federation-large-header nav ul li {
    margin-right: 20px;
}

.federation-large-header nav ul li a {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 17px;
}

.federation-header-equipos{
    height:70px ;
    background-color: white;
}

/* Hero Section */
.federation-hero-section {
    background-size: cover;
    background-position: center;
    padding: 0 150px ;
    text-align: left;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    height: 450px;
}

.federation-hero-text h1 {
    font-size: 38px;
    font-weight: 700;
    line-height: 40px;
  
}

.federation-hero-text h2 {
    font-size: 38px;
    font-weight: 400;
    line-height: 40px;
  
}

.federation-content-section {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .federation-column-left {
    width: 40%;
  }
  
  .federation-column-right {
    width: 60%;
  }
  

