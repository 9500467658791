.members-activity-card {
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  border-left: solid 8px var(--primary);
  display: flex;

  position: relative;
  margin-bottom: 10px;
  transition: border-left-width 0.05s ease-in-out;
  cursor: pointer;
}
.members-activity-card:hover {
  border-left-width: 10px;
}
.activity-details {
  padding: 10px;
  flex-grow: 1;
}

.activity-team-asociation {
  font-size: 16px;
  font-weight: 600;
  color: #888888;
  margin-bottom: 10px;
}
.activity-team-name {
  font-size: 15px;
  font-weight: 500;
}
.activity-team-icon {
  max-height: 50px;
  max-width: 50px;
  border: solid 1px #e5e5e5;
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
}

.activity-team-icon img {
  height: 100%;
  width: 100%;
}

.activity-card-inputs {
  display: flex;
  gap: 10px;
  height: 35px;

}

.activity-card-input {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  text-align: center;
 width:80px;
 cursor: pointer;
}



.activity-team-details {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.activity-info {
 
  display: flex;
  flex-direction: column;
}



.activity-card-status-icon{
  height: 40px;
  width: 40px;
}