.custom-table {
   
    margin: 5px 0; 
  }

  .custom-table .ant-table-tbody > tr > td {
    padding: 10px !important;
    border: none !important;

  }
  
  .info-text{
    display: flex;
    align-items: center;
    gap: 5px;
    color: #979EA8;
    font-weight: 500;
    font-size: 14px
  }
.info-icon{
    margin-bottom: 2px;
    font-size: 20px;
}
  :where(.css-dev-only-do-not-override-3rel02).ant-table-wrapper .ant-table-tbody >tr >th, :where(.css-dev-only-do-not-override-3rel02).ant-table-wrapper .ant-table-tbody >tr >td {
    border-bottom: 15px solid var(--app-container) !important;
    font-weight: 500!important;
    font-size: 15px!important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  
  }
  :where(.css-dev-only-do-not-override-3rel02).ant-table-wrapper .ant-table {
    border-radius: 0 !important;
  }

  .row-wrapper-pink {
    border-left: 2px solid pink;
  }
  
.row-wrapper-blue {
    border-left: 20px solid blue
  }