.formitem {
  margin-bottom: 5px;
}

.formitem .label {
  position: relative;
  font-size: 12px;
  font-weight: 500;
  color: #979ea8;
  top: 5px;

}
:where(.css-dev-only-do-not-override-3rel02).ant-form-item{
  margin-bottom: 0px !important;
}