.event-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.event-type{
    font-size: 25px;
    font-weight: 500;
}
.event-step{
    font-size: 20px;
    font-weight: 500;
    color:#979EA8;
}
.event-time{
    font-size: 30px;
    font-weight: 700;
    color:#90FB3D;
}
.event-team-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.event-team{
    width: 200px;
    height: 200px;
    cursor:pointer;
    
}
.event-btn{
    width: 100%;
    background-color:#90FB3D;
    color: #fff;  
    border-radius:30px;
    height:48px;
    font-weight:500;
    font-size:21px;
    border:none !important;
}
.event-btn:hover{
    background-color:#90FB3D !important;
    color: #fff !important; 
    border:none !important;
}