
.fixture-partido {
    background-color: #f2f2f2;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width:300px; /* Tamaño mínimo para evitar que se colapsen demasiado */
    text-align: center;
  }
  
  .fixture-partido .fecha-partido {
    font-size: 16px;
    font-weight: bold;
  }
  
  .fixture-partido .club-local,
  .fixture-partido .club-visitante {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .fixture-partido .club-local img,
  .fixture-partido .club-visitante img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .fixture-partido .arrow {
    font-size: 20px;
  }