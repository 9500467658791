.staff-card{
  padding: 20px;
  border-radius: 8px;
  border:solid 1px #e5e5e5;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  
}
.staff-card-shirt{
  min-width: 150px;
}


.staff-card-stats{
  padding: 0 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

  .staff-card-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
 
  }
  
  .staff-card-stat-title {
    font-size: 14px;
    font-weight: 500;
    color: #888;
  }
  
  .staff-card-stat-value {
    font-size: 27px;
    font-weight: 700;
  }
  .staff-card-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }