.button-action {
  color: #a6a6a6; /* Color base para todos los botones */
  transition: color 0.3s ease;
  cursor: pointer;
}


.button-action.check {
  color: #0054e9;
}


.button-action.cancel {
  color: #e90f16;
}


.button-action:hover {
  color: #020ad9;
}
