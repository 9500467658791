.login-layout {
  display: flex;
  height: 100vh;
  position: relative;
}

.login-left {
  flex: 1;
  background-color: white;

  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-left-text{
  font-size: 28px;
  font-weight: 600;
  color: black;
}
.login-left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  transform: translate(0, 25%);
}

.login-right {
  flex: 1;
  background-color: blue;
  background-image: url("./assets/backgroundImage.svg");
  background-size: cover;
  background-position: center;
  position: relative;
}

.login-right-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  transform: translate(0, 40%);
}

.login-text {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: white;
}

.login-image-conteiner {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 0;
  z-index: 10;
}
.login-form{
 
  width: 80%;
}
.login-input-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}
.login-input {
  border: 1px solid #e5e5e5; 
  outline: none;
  padding: 5px;
  border-radius: 4px; 
}

.login-input:focus {
  border-color: #b3b3b3; 
  box-shadow: 0 0 0 2px rgba(229, 229, 229, 0.5); 
}
.login-input-text{
  font-weight: 500;
  font-size: 17px;
  color: #979EA8;
  margin-bottom: 5px;
}
.login-label{
  color:#727272;
  font-weight: 400;
  font-size: 15px;
}
.submit-container{
  width: 100%;
  margin-top: 20px;
}
.login-form-button{
  margin-top: 20px;
  width: 100%;
  background-color: var(--primary);
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  border-radius:30px;
  padding: 8px 0;
  cursor: pointer;
}