
.main-container{
    display: flex;
    height: 100vh;
    flex-direction: column;
}
.main-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    padding: 0 50px;
    color: white;
}
.main-layout{
    display: flex;
    flex:1 1 0%;
  
}
.main-content{

    padding: 1rem 1rem 0 1rem ;
    flex-direction: column;
    flex: 1 1 0%;
    display: flex;
    background-color: var(--app-container);
    flex-grow: 1;
    width: calc(100vh - 360px);
    overflow: hidden;
}

.main-logo{
    height: 50px;
    width: 100px;
    display: flex;
    align-items: center;
    cursor: pointer;
   
   
}
.aside {
    max-width: 250px;
    background-color: white;

    overflow-y: auto;
    padding: 1rem;
  }
  
  .aside-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 20px;
  }
  
  .avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .avatar-image {

    width: 165px;
    height:165px;
  }
  
.club-text{
 
  width: 100%;
}
  .club-name {
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    font-weight: bold;
    text-align: center;
    overflow: hidden;        
    text-overflow: ellipsis; 
    white-space: nowrap; 
  
  }
  
  .nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .sider-button {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    border-radius: 0.375rem;
    gap:10px;
    font-size: 0.875rem;
    font-weight: 500;
    background-color: transparent;
    color: #000;
    text-decoration: none;
    border: 1.5px solid  #e5e5e58c;
  }
  
  .sider-button.active {
    border-color: var(--primary);
    background-color: var(--primary);
    color: white;
  }
  

  .icon {
    margin-right: 0.5rem;
    height: 1.25rem;
    width: 1.25rem;
  }
  
 

:where(.css-dev-only-do-not-override-3rel02).ant-layout .ant-layout-sider {
    background:  rgb(3 7 18) !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-menu-inline.ant-menu-root .ant-menu-item >.ant-menu-title-content, :where(.css-dev-only-do-not-override-3rel02).ant-menu-inline.ant-menu-root .ant-menu-submenu-title >.ant-menu-title-content {

    text-align: center!important;
    color: white!important;
    font-size: 16px; 
    
}

:where(.css-dev-only-do-not-override-3rel02).ant-menu-dark, :where(.css-dev-only-do-not-override-3rel02).ant-menu-dark>.ant-menu {
    background: none!important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, :where(.css-dev-only-do-not-override-3rel02).ant-menu-dark>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity))!important;
}
ul li:before {
    content: '' !important;
}