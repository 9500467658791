.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 20px;
  z-index: 999;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  color: #333; 
}

.modal--primary {
  background-color: #444444;
  color: #3c763d;
  border-top: 5px solid #3c763d;
}

.modal--success {
  background-color: #a7ed8b;
  color: #3c763d;
  border-top: 5px solid #3c763d;
}

.modal--info {
  background-color: #88d0f4;
  color: #31708f;
  border-top: 5px solid #31708f;
}

.modal--warning {
  background-color: #f1df58;
  color: #000000;
  border-top: 5px solid #8a6d3b;
}

.modal--error {
  background-color: #f07f7f;
  color: #0a0a0a;
  border-top: 5px solid #a94442;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-title {
  flex: 1;

  font-size: 24px;
  font-weight: bold;
}

.modal-back-button,
.modal-close-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.modal-content {

  max-height: calc(90vh - 60px);
  margin-top: 5px;
}
